import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import React, { useContext, useState, useEffect } from "react";
import { WalletContext } from "../store/context/WalletContext";
import { switchNetwork } from "../utils/connector";
import "./Button/Button.scss";

const Button: React.FC = () => {
  const { error } = useWeb3React();
  const { setOpenWallet } = useContext(WalletContext);
  const [wrongNetwork, setWrongNetwork] = useState(false);

  useEffect(() => {
    setWrongNetwork(error instanceof UnsupportedChainIdError);
  }, [error]);

  return (
    <button
      className={wrongNetwork ? "btn-error" : "btn-primary"}
      onClick={wrongNetwork ? switchNetwork : () => setOpenWallet(true)}
    >
      {wrongNetwork ? "Wrong Network" : "Connect Wallet"}
    </button>
  );
};

export default Button;
