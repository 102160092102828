import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './AdvertisingPage.scss';
import TokenTradingApp from './PartnerDetails';
import ProgressBar from '../components/ditributor';
import TokenDistributor from '../components/TokenDistributor';
import CryptoSwapAndStake from '../pages/ProfilePage/CryptoSwapAndStake';
import Staking from './Stake';
import NFTList from './NFTList';
import Spinner from '../components/soin/Spinner';

const AdvertisingPage = () => {
  const [timeLeft, setTimeLeft] = useState({ months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 });
  const navigate = useNavigate(); // Initialize navigate

  const calculateTimeLeft = () => {
    const launchDate = new Date('2025-08-30T00:00:00').getTime();
    const currentTime = new Date().getTime();
    const difference = launchDate - currentTime;
  
    if (difference > 0) {
      const totalDays = Math.floor(difference / (1000 * 60 * 60 * 24));
      const months = Math.floor(totalDays / 30.44); // Approximate days in a month
      const days = totalDays % 30.44; // Remaining days after full months
  
      setTimeLeft({
        months,
        days: Math.floor(days),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      });
    } else {
      setTimeLeft({ months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };
  

  useEffect(() => {
    const timer = setInterval(() => calculateTimeLeft(), 1000);
    return () => clearInterval(timer);
  }, []);

  const handleRedirect = () => {
    navigate('/usdt'); // Redirect to the /buy route
  };

  return (
    <div className="advertising-page">
      <div className="content-container">
        <h1 className="headline">Pre-Launch Token Sale</h1>
        {/* <p className="subheadline">
          Buy <span>TRT</span> token now and enjoy an exclusive <span>70% discount</span> in Stage One! 
        </p> */}
        <p className="subheadline">
        <h2>Upcoming Developments</h2>
  <p>TRT will be listed on top exchanges in 6 months. Our team is actively collaborating with major platforms.</p>
  <p>Meanwhile, you can earn by staking USDT, inviting friends, and accessing our exclusive rewards system.</p>        </p>
        {/* <p className="subheadline">
          Buy <span>TRT</span> token now and enjoy an exclusive <span>30% discount</span> in Stage Three! 
        </p> */}
        <p className="subheadline">
        </p>
        <div className="timer">
        <div className="timer-box">
            <span>{timeLeft.months}</span>
            <p>Months</p>
          </div>
          <div className="timer-box">
            <span>{timeLeft.days}</span>
            <p>Days</p>
          </div>
          <div className="timer-box">
            <span>{timeLeft.hours}</span>
            <p>Hours</p>
          </div>
          <div className="timer-box">
            <span>{timeLeft.minutes}</span>
            <p>Minutes</p>
          </div>
          <div className="timer-box">
            <span>{timeLeft.seconds}</span>
            <p>Seconds</p>
          </div>
        </div>
        {/* <ProgressBar/> */}
        {/* <TokenDistributor/> */}

{/* <NFTList/> */}

        {/* <ProgressBar/> */}
        {/* <TokenTradingApp/> */}

        {/* <button className="cta-button" onClick={handleRedirect}>
          Buy Now
        </button> */}
                 {/* <TokenTradingApp/> */}
      </div>

    </div>
  );
};

export default AdvertisingPage;
