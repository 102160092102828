import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useSnackbar } from "notistack"; // Import Notistack
import styles from "./Withdraw.module.scss"; // Import SCSS file
import Withdraws from "../utils/ClaimRewardsButton";

const CONTRACT_ADDRESS = "0xA8e196f9Af00C9875Ad2751Be99493b074f973dF"; // Replace with actual contract address
const CONTRACT_ABI = [
  {
    "constant": true,
    "inputs": [{ "name": "user", "type": "address" }],
    "name": "availableBalance",
    "outputs": [{ "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [{ "name": "amount", "type": "uint256" }],
    "name": "withdrawAvailableBalance",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [{ "name": "user", "type": "address" }],
    "name": "getUserInterestRate",
    "outputs": [{ "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  }
];

const Withdraw: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [availableBalance, setAvailableBalance] = useState<string>("0");
  const [interestRate, setInterestRate] = useState<string>("0");
  const [withdrawAmount, setWithdrawAmount] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // Connect Wallet Function
  const connectWallet = async () => {
    if (!window.ethereum) {
      enqueueSnackbar("MetaMask not detected! Please install it.", { variant: "error" });
      return;
    }

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

      setWalletAddress(await signer.getAddress());
      enqueueSnackbar("Wallet connected successfully!", { variant: "success" });

      const contractInstance = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
      setContract(contractInstance);
    } catch (error) {
      console.error("Wallet connection failed:", error);
      enqueueSnackbar("Failed to connect wallet. Try again.", { variant: "error" });
    }
  };

  // Fetch available balance
  const fetchAvailableBalance = async () => {
    if (!walletAddress || !contract) return;
    try {
      const balance = await contract.availableBalance(walletAddress);
      setAvailableBalance(ethers.utils.formatUnits(balance, 18));
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  // Fetch interest rate
  const fetchInterestRate = async () => {
    if (!walletAddress || !contract) return;
    try {
      const rate = await contract.getUserInterestRate(walletAddress);
      setInterestRate(rate.toString());
    } catch (error) {
      console.error("Error fetching interest rate:", error);
    }
  };

  // Withdraw function
// Withdraw function
const handleWithdraw = async () => {
  if (!walletAddress || !contract) {
    enqueueSnackbar("Please connect your wallet!", { 
      variant: "warning",
      anchorOrigin: { vertical: "top", horizontal: "center" }
    });
    return;
  }

  const amount = parseFloat(withdrawAmount);
  if (isNaN(amount) || amount < 1) {
    enqueueSnackbar("Minimum withdrawal is $1", { 
      variant: "warning",
      anchorOrigin: { vertical: "top", horizontal: "center" }
    });
    return;
  }

  try {
    setLoading(true);
    const tx = await contract.withdrawAvailableBalance(
      ethers.utils.parseUnits(withdrawAmount, 18)
    );
    await tx.wait();

    enqueueSnackbar(`Your ${withdrawAmount} USDT Withdrawal Successful!`, { 
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" }
    });

    setWithdrawAmount("");
    fetchAvailableBalance();
    fetchInterestRate();
    setIsModalOpen(false);
  } catch (error) {
    console.error("Withdrawal failed:", error);
    enqueueSnackbar("Withdrawal failed! Please try again.", { 
      variant: "error",
      anchorOrigin: { vertical: "top", horizontal: "center" }
    });
  } finally {
    setLoading(false);
  }
};

  // Fetch data when wallet is connected
  useEffect(() => {
    if (walletAddress && contract) {
      fetchAvailableBalance();
      fetchInterestRate();
    }
  }, [walletAddress, contract]);

  return (
    <div>

      {walletAddress ? (
        <>
                    {/* <Withdraws/> */}

          <div className="card2">
            <h5>Available Balance</h5>
            <p>{availableBalance} USDT</p>
            <button className={styles.withdrawButton} onClick={() => setIsModalOpen(true)}>
              Withdraw
            </button>
          </div>
          <br />
          <div className="card2">
            <h5>DAILY RETURN</h5>
            <p>{interestRate}%</p>
          </div>
        </>
      ) : (
        <button className={styles.connectButton} onClick={connectWallet}>
          Connect Wallet
        </button>
      )}

      {/* Withdraw Popup Modal */}
      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>WITHDRAW FUNDS</h3>
            <input
              type="number"
              placeholder="Enter amount"
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
              min="5"
            />
            <button onClick={handleWithdraw} disabled={loading}>
              {loading ? "Processing..." : "Withdraw"}
            </button>
            <button onClick={() => setIsModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Withdraw;
