import React, { useEffect, useState } from "react";
import "./PopupCard.scss";
import usdtLogo from "../assets/logo/default-image-url.jpg"; // USDT کا لوگو امپورٹ کریں
// import { TRUE } from "sass";

const PopupCard: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    const hasClosed = localStorage.getItem("popupClosed");
    if (!hasClosed) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem("popupClosed", "true"); // Save user choice
  };

  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-card">
        <button className="close-btn" onClick={handleClose}>✖</button>
        <img src={usdtLogo} alt="USDT Logo" className="usdt-logo" />
        <h2>🚀 Unlock the Leadership Package!</h2>
        <p>
          Now all registration can be done with <strong>USDT BEP20</strong> and with the
          minor point of <strong>BNB</strong>.
        </p>
          <p>
          If you have a strong community and have shown outstanding performance in a previous system,  
          this opportunity is for you! 🎉
        </p>
        <div className="info-box">
          {/* <h3>📢 Important Staking Update</h3>
          <p>
            For all TRT token stakers! Joining with tokens has been 
            <strong> discontinued </strong> for new users. However, the 
            <strong> TRT tokens you have already staked </strong> will continue 
            to earn a <strong>daily percentage</strong>.
          </p>
          <p>
            Until you reach <strong>300% profit</strong>, you will have the option 
            to <strong>claim your daily earned tokens</strong>. This feature will 
            soon be available on your dashboard.
          </p> */}
      
        <ul className="benefits-list">
          <li>💰 <strong>Exclusive Salary Package</strong></li>
          <li>🎁 <strong>Additional Bonuses & Benefits</strong></li>
          <li>🏆 <strong>Special Leadership Rewards</strong></li>
        </ul>
        <p>Contact us now to claim these exclusive benefits!</p>
      
        </div>

        <a href="https://t.me/Ragha_dom" target="_blank" className="join-btn">
          🔗 Join Now
        </a>      </div>
    </div>
  );
};

export default PopupCard;
