import React, { useState } from 'react';
import { motion } from 'framer-motion';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useWeb3React } from '@web3-react/core';

import './Modals.scss';
import Backdrop from './Backdrop';
import { modalVaraints } from '../../helpers/animation';
import { ReactComponent as Share } from '../../assets/icons/share.svg';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { ReactComponent as NoReferrals } from '../../assets/icons/no_referrals.svg';
import { ReactComponent as ProcessIcon } from '../../assets/icons/transaction_processing.svg';
import { ReactComponent as LevelNewbie } from '../../assets/icons/level_newbie.svg';
import { ReactComponent as LevelSilver } from '../../assets/icons/level_silver.svg';
import { ReactComponent as LevelGolden } from '../../assets/icons/level_golden.svg';
import { ReactComponent as LevelBRONZI } from '../../assets/icons/level_bronzi.svg';
import { ReactComponent as LevelDiamond } from '../../assets/icons/level_diamond.svg';
import Button from '../Button';
import { useUpdateEffect, useUserStakingData } from '../../hooks';
import { getSlicedValue } from '../../helpers/methods';
import LevelDetailsPopup from './LevelDetailsPopup';

export enum LevelStatus {
  NEWBIE,
  SILVER,
  GOLDEN,
  BRONZI,
  DIAMOND,
}

interface IReferralsModalProps {
  isOpen: boolean;
  onClose: () => void;
  referralEarnings: number;
  referredUsers: number;
  referralLevel: LevelStatus;
  onClaimEarnings: () => void;
}

// const getBaseUrl = () => {
//   const splitedurl = window.location.href.split('://');
//   const domain = splitedurl[1].split('/')[0];
//   return `${splitedurl[0]}://${domain}`;
// };

// const CopyText = ({ address }: { address: string }) => {
//   const [copied, setCopied] = useState(false);

//   useUpdateEffect(() => {
//     if (!copied) return;

//     const clear = setTimeout(() => setCopied(false), 3000);

//     return () => {
//       clearTimeout(clear);
//     };
//   }, [copied]);

//   return copied ? (
//     <p style={{ lineHeight: '24px' }}>Copied</p>
//   ) : (
//     <CopyToClipboard text={address} onCopy={() => setCopied(true)}>
//       <div className="pointer">
//         <Copy />
//       </div>
//     </CopyToClipboard>
//   );
// };

const LevelCard = ({
  level,
  isUnlocked,
  onViewMore,
}: {
  level: LevelStatus;
  isUnlocked: boolean;
  onViewMore: () => void;
}) => {
  const getLevelIcon = (level: LevelStatus) => {
    switch (level) {
      case LevelStatus.NEWBIE:
        return <LevelNewbie />;
      case LevelStatus.SILVER:
        return <LevelSilver />;
      case LevelStatus.GOLDEN:
        return <LevelGolden />;
      case LevelStatus.BRONZI:
        return <LevelBRONZI />;
      case LevelStatus.DIAMOND:
        return <LevelDiamond />;
      default:
        return null;
    }
  };

  return (
    <div className={`level-card ${isUnlocked ? 'unlocked' : 'locked'}`}>
      {getLevelIcon(level)}
      <h3>{LevelStatus[level]}</h3>
      <p>{isUnlocked ? 'Unlocked' : 'Locked'}</p>
      <Button variant="secondary" onClick={onViewMore}>
        View More
      </Button>
    </div>
  );
};

const ReferralsModal: React.FC<IReferralsModalProps> = ({
  isOpen,
  onClose,
  referralLevel,
  onClaimEarnings,
}) => {
  const [copied, setCopied] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<string>('');
  const [personActivity, setPersonActivity] = useState<any>(null); // Replace with actual type
  const [showLevelDetails, setShowLevelDetails] = useState<LevelStatus | null>(null); // For LevelDetailsPopup
  const { account } = useWeb3React();
  const {
    userData: { referralEarnings: earnings, referred_users, referralLevel: level },
  } = useUserStakingData();

  const handlePersonChange = (value: string) => {
    setSelectedPerson(value);
    // Fetch activity for the selected person
    // For example: setPersonActivity(fetchActivityForPerson(value));
  };

  const handleViewMore = (level: LevelStatus) => {
    setShowLevelDetails(level);
  };

  const closeLevelDetailsPopup = () => {
    setShowLevelDetails(null);
  };

  return (
    <>
      <Backdrop handleClose={onClose} isOpen={isOpen}>
        <motion.div
          className="referrals_modal"
          onClick={(e) => e.stopPropagation()}
          variants={modalVaraints}
          animate="animate"
          initial="initial"
          exit="exit"
        >
          <div className="referrals_modal-content">
            {/* Header Section */}
            {/* <div className="modal-header">
              <h3>Copy Link</h3>
              {copied ? (
                <Button variant="primary_gradient">Link Copied</Button>
              ) : (
                <CopyToClipboard text={`${getBaseUrl()}?ref=${account}`} onCopy={() => setCopied(true)}>
                  <Button variant="primary_gradient">
                    <Share />
                    <span>Copy Link</span>
                  </Button>
                </CopyToClipboard>
              )}
            </div> */}

            {/* Referral List Section */}
            <div className="content">
              <h4 className="mb-10">Direct Members</h4>
              <label htmlFor="referral-select" className="sr-only">
                view more
              </label>

              <button className="dropdown-toggle" onClick={() => setShowDropdown(!showDropdown)}>
                {showDropdown ? 'Hide Referrals' : 'Show Referrals'}
              </button>
              {showDropdown && (
                <div className="referrals_dropdown">
                  {!referred_users ? (
                    <div className="loader">
                      <ProcessIcon />
                    </div>
                  ) : !referred_users.length ? (
                    <div className="no_referrals">
                      <NoReferrals />
                      <p>No referrals on your list.</p>
                    </div>
                  ) : (
                    <div className="referrals_list">
                      {referred_users.map((ref, index) => (
                        <div key={index.toString()} className="referral-item">
                          <p>{getSlicedValue(ref)}</p>
                          {/* <CopyText address={ref} /> */}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Activity Section for Selected Person */}
            {selectedPerson && personActivity && (
              <div className="person-activity">
                <h4>Activity for {getSlicedValue(selectedPerson)}</h4>
                <div className="activity-details">
                  {personActivity.map((activity: any, index: number) => (
                    <div key={index} className="activity-item">
                      <p>{activity.details}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Level Cards Section with Scrolling */}
            <div className="level-cards-container">
              <div className="level-cards">
                {Object.keys(LevelStatus)
                  .filter((key) => !isNaN(Number(LevelStatus[key as any]))) // Ensure only numeric keys
                  .map((key, index) => (
                    <LevelCard
                      key={key}
                      level={Number(LevelStatus[key as any])}
                      isUnlocked={Number(LevelStatus[key as any]) <= referralLevel}
                      onViewMore={() => handleViewMore(Number(LevelStatus[key as any]))}
                    />
                  ))}
              </div>
            </div>
          </div>
        </motion.div>
      </Backdrop>

      {showLevelDetails !== null && (
        <LevelDetailsPopup
          isOpen={showLevelDetails !== null}
          onClose={closeLevelDetailsPopup}
          level={showLevelDetails}
        />
      )}
    </>
  );
};

export default ReferralsModal;
