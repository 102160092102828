import React from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-container">
        <br /><br /><br /><br />
      <h2>Privacy Policy - Trust Trade</h2>

      <section>
        <h3>1. Introduction</h3>
        <p>
          Welcome to Trust Trade. We are committed to protecting your privacy and ensuring
          that your personal information is handled securely. This policy outlines how
          we collect, use, and safeguard your data.
        </p>
      </section>

      <section>
        <h3>2. Information We Collect</h3>
        <p>We may collect the following types of information:</p>
        <ul>
          <li>Personal details (Name, Email, Contact Info)</li>
          <li>Financial data (Wallet Address, Transaction History)</li>
          <li>Device and browser information</li>
          <li>Cookies and usage data</li>
        </ul>
      </section>

      <section>
        <h3>3. How We Use Your Information</h3>
        <p>Your information is used for:</p>
        <ul>
          <li>Processing transactions and stake earnings</li>
          <li>Providing customer support</li>
          <li>Enhancing security and fraud prevention</li>
          <li>Improving user experience</li>
        </ul>
      </section>

      <section>
        <h3>4. Data Security</h3>
        <p>
          We implement industry-standard security measures to protect your data from
          unauthorized access, alteration, or loss.
        </p>
      </section>

      <section>
        <h3>5. Third-Party Sharing</h3>
        <p>
          We do not sell or rent your personal data. However, we may share limited
          information with:
        </p>
        <ul>
          <li>Regulatory authorities if required by law</li>
          <li>Payment processing services</li>
          <li>Trust Trade partners for analytics</li>
        </ul>
      </section>

      <section>
        <h3>6. Your Rights</h3>
        <p>You have the right to:</p>
        <ul>
          <li>Request access to your personal data</li>
          <li>Request correction or deletion of data</li>
          <li>Opt out of marketing communications</li>
        </ul>
      </section>

      <section>
        <h3>7. Changes to This Policy</h3>
        <p>
          We may update this policy from time to time. Any changes will be posted on
          this page.
        </p>
      </section>

      <footer>Last Updated: January 2025</footer>
    </div>
  );
};

export default PrivacyPolicy;
