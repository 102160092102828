import React, { useContext, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { AnimatePresence } from "framer-motion";

import { Button, UnlockWallet } from "../../components";

// import { ReactComponent as ExternalLink } from "../../assets/icons/external_link.svg";
// import { ReactComponent as InfoIcon } from "../../assets/icons/alert_circle.svg";
import { ReactComponent as Loader } from "../../assets/icons/transaction_processing.svg";

import { TransactionContext } from "../../store/context/TransactionContext";

import { IDepositData } from "../../store/types";
import Deposit from "./Deposit";
// import ReferralsModal from "../../components/Modals/ReferralModal";
// import { STAKING_ADDRESS } from "../../utils/address";
import DepositModal from "../../components/Modals/DepositModal";
import { useUserStakingData } from "../../hooks";
import ProfilePage from "../ProfilePage";
import AdvertisingPage from "../../utils/AdvertisingPage";
import Staking from "../../utils/Stake";
import StepByStepGuide from "../ProfilePage/StepByStepGuide";
import ImageSlider from "../../components/ContactUs/ImageSlider";
import Roadmap from "../../services/Roadmap";
// import Farms from "./Farm";



const Farm: React.FC = () => {

  const { account, library, chainId } = useWeb3React();
  const { userData, isLoading, refetch } = useUserStakingData();
  const { setTransaction, loading } = useContext(TransactionContext);
  const [depositModal, setDepositModal] = useState<IDepositData | null>(null);

  const handleGetContractDetails = async () => {};

  const handleApprove = async () => {
    if (!account || !chainId) return;
    try {
      setTransaction({ loading: true, status: "pending" });
      const { setApprove } = await import("../../utils/userMethods");
      await setApprove(account, library?.provider, chainId);
      await refetch();
      setTransaction({ loading: true, status: "success" });
    } catch (error: any) {
      if (error?.code === 4001) {
        return setTransaction({
          loading: true,
          status: "error",
          message: error.message,
        });
      }
      setTransaction({ loading: true, status: "error" });
    }
  };

 

 


  const renderMethods = (
    <div className="form_container">

      {<Deposit setDepositModal={setDepositModal} />}
     
      
    </div>
  );

  return (

    
    <AnimatePresence>
      
<ProfilePage/>
<br />
        <div className="farm_content">
          {/* <hr className="mb-32" /> */}
          {!account ? (
            // <UnlockWallet />
          
            <div className="loader">
              <Loader />
            </div>
          ) : !userData.isAllowanceApproved ? (
            <Button disabled={loading || isLoading} onClick={() => handleApprove()}>
              Approve
            </Button>
          ) : (
            renderMethods
          )}
        </div>

      {depositModal && (
        <DepositModal
          key={"deposit"}
          modal={depositModal ? true : false}
          handleClose={() => setDepositModal(null)}
          data={depositModal}
          handleGetContractDetails={handleGetContractDetails}
        />
      )}
      <br />
            <br /><br />
            {/* <Roadmap/><br /><br /><br /> */}
       {/* <StepByStepGuide/>  */}
    </AnimatePresence>

    
  );
  
};

export default Farm;
