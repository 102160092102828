import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import TokenDistributorAbi from "../abi/TokenDistributorAbi.json";
import './ClaimPage.scss';
import AdvertisingPage from "../utils/AdvertisingPage";

const CONTRACT_ADDRESS = "0x70709E60A74Cb030D8959FC45714b7Db0d6DBcdB";

const ProgressBar: React.FC = () => {
  const [totalClaimed, setTotalClaimed] = useState<string>("0.00");
  const [progress, setProgress] = useState<number>(0);
  const TOTAL_TOKENS = 50000; // Total tokens available for claiming
  // const TOTAL_TOKEN = 458000; // Total tokens available for claiming

  useEffect(() => {
    connectWallet();
  }, []);

  const connectWallet = async () => {
    if (!window.ethereum) {
      alert("MetaMask is not installed!");
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);
    fetchTotalClaimed(provider);
  };

  const fetchTotalClaimed = async (provider: ethers.providers.Web3Provider) => {
    try {
      const contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        TokenDistributorAbi,
        provider
      );
      const total = await contract.totalTokensClaimed();
      const totalInTokens = parseFloat(ethers.utils.formatUnits(total, 18));
      setTotalClaimed(totalInTokens.toFixed(2));
      setProgress((totalInTokens / TOTAL_TOKENS) * 100);
    } catch (error) {
      console.error("Error fetching total claimed tokens:", error);
    }
  };

  return (
    <div className="containes">
      <div>
        <p><strong>Total Tokens Claimed:</strong> {totalClaimed} / {TOTAL_TOKENS} TRT</p>
        <div className="progress-bar">
          <div
            className="progress"
            style={{ width: `${progress}%` }}
          >
            {progress.toFixed(2)}%
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
