import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Backdrop from './Backdrop';
import './Modalss.scss';
import CryptoIcon from '../../assets/logo/token.png';

interface ITransactionModal {
  modal: boolean;
  status: 'pending' | 'success' | 'error';
  message?: string;
  onClose?: () => void;
}

const TransactionModal: React.FC<ITransactionModal> = ({ modal, status, message, onClose }) => {
  useEffect(() => {
    if (status !== 'pending' && modal) {
      const timer = setTimeout(() => {
        if (onClose) onClose();
      }, 3000); // Auto close after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [status, modal, onClose]);

  return (
    <Backdrop isOpen={modal}>
      <motion.div 
        className="transaction_modal"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <div className="crypto_icon_container">
          <img src={CryptoIcon} alt="Crypto Icon" className="crypto_icon" />
        </div>

        {/* Loading Spinner */}
        {status === 'pending' && (
          <>
            <div className="spinner_container">
              <div className="spinner"></div>
              <div className="spinner second"></div>
              <div className="spinner third"></div>
            </div>
            <p className="loading_text">Processing Transaction...</p>
          </>
        )}

        {/* Success Message */}
        {status === 'success' && (
          <p className="success_text">🎉 Transaction Successful!</p>
        )}

        {/* Error Message */}
        {status === 'error' && (
          <p className="error_text">⚠️ Transaction Failed!</p>
        )}

        {message && <p className="transaction_message">{message}</p>}
      </motion.div>
    </Backdrop>
  );
};

export default TransactionModal;
