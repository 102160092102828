import React, { createContext, ReactNode, useState, useEffect } from "react";
import WalletModal from "../../components/Modals/WalletModal";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

interface IWalletContext {
  openWallet: boolean;
  setOpenWallet: React.Dispatch<React.SetStateAction<boolean>>;
  connectWallet: () => void;
  disconnectWallet: () => void;
  walletAddress: string | null;
}

export const WalletContext = createContext<IWalletContext>({
  openWallet: false,
  setOpenWallet: () => {},
  connectWallet: () => {},
  disconnectWallet: () => {},
  walletAddress: null,
});

const WalletContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [openWallet, setOpenWallet] = useState(false);
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [connector, setConnector] = useState<WalletConnect | null>(null);

  useEffect(() => {
    if (connector && connector.connected) {
      setWalletAddress(connector.accounts[0]);
    }
  }, [connector]);

  const connectWallet = async () => {
    const newConnector = new WalletConnect({
      bridge: "https://bridge.walletconnect.org",
    });

    if (!newConnector.connected) {
      await newConnector.createSession();
      QRCodeModal.open(newConnector.uri, () => {});
    }

    newConnector.on("connect", (error, payload) => {
      if (error) throw error;
      const { accounts } = payload.params[0];
      setWalletAddress(accounts[0]);
      QRCodeModal.close();
    });

    setConnector(newConnector);
  };

  const disconnectWallet = () => {
    if (connector) {
      connector.killSession();
      setWalletAddress(null);
      setConnector(null);
    }
  };

  const handleClose = () => setOpenWallet(false);

  return (
    <WalletContext.Provider
      value={{ openWallet, setOpenWallet, connectWallet, disconnectWallet, walletAddress }}
    >
      {children}
      <WalletModal modal={openWallet} handleClose={handleClose} />
    </WalletContext.Provider>
  );
};

export default WalletContextProvider;
