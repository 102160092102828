const TOKEN_ADDRESS = {
  56: "0x55d398326f99059fF775485246999027B3197955",
  4002 : "0x67d0C17B2c90ca0aa68e6d646eaD55db6a5B0A5A",
};

const STAKING_ADDRESS = {
  56: "0xA8e196f9Af00C9875Ad2751Be99493b074f973dF",
  4002 : "0x12c635f10f2c48d9564c5c1a8bed1e687cd326c4",
};

export { TOKEN_ADDRESS, STAKING_ADDRESS };
