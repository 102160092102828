import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import TokenDistributorAbi from "../abi/TokenDistributorAbi.json";
import './TokenDistributor.scss';
// import ClaimPage from "./ditributor";
import ProgressBar from "./ditributor";
import AdvertisingPage from "../utils/AdvertisingPage";

const CONTRACT_ADDRESS = "0x70709e60a74cb030d8959fc45714b7db0d6dbcdb";
const TRT_TOKEN_ADDRESS = "0x16963195bd97caba6ee12dc2350e662fac2e248a";  // Add the address of your TRT token contract

const TokenDistributor: React.FC = () => {
  const [tokenPrice, setTokenPrice] = useState<string>("0.00");
  const [claimableAmount, setClaimableAmount] = useState<string>("0.00");
  const [addressValue, setAddressValue] = useState<string>("0.00");
  const [userAddress, setUserAddress] = useState<string>("");

  useEffect(() => {
    connectWallet();
  }, []);

  const connectWallet = async () => {
    try {
      if (!window.ethereum) {
        alert("MetaMask is not installed!");
        return;
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await provider.send("eth_requestAccounts", []);
      setUserAddress(accounts[0]);
      fetchContractData(provider, accounts[0]);
    } catch (error) {
      console.error("Error connecting wallet:", error);
    }
  };

  const fetchContractData = async (provider: ethers.providers.Web3Provider, user: string) => {
    try {
      const contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        TokenDistributorAbi,
        provider
      );

      const price = await contract.tokenPrice();
      setTokenPrice(parseFloat(ethers.utils.formatEther(price)).toFixed(2));

      const claimable = await contract.getUserClaimableAmount(user);
      setClaimableAmount(parseFloat(ethers.utils.formatEther(claimable)).toFixed(2));

      const value = await contract.addressValues(user);
      setAddressValue(parseFloat(ethers.utils.formatUnits(value, 18)).toFixed(2));
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  };

  const claimTokens = async () => {
    try {
      if (!window.ethereum) {
        alert("MetaMask is not installed!");
        return;
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        TokenDistributorAbi,
        signer
      );

      const tx = await contract.claimTokens();
      await tx.wait();
      alert("Tokens claimed successfully!");
      fetchContractData(provider, userAddress);
    } catch (error) {
      console.error("Error claiming tokens:", error);
    }
  };

  // Function to import the TRT token into the wallet
  const addTokenToWallet = async () => {
    if (window.ethereum) {
      try {
        const tokenSymbol = "TRT";  // Token symbol (you can change this)
        const tokenDecimals = 18;   // Decimals (adjust this if different)

        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: TRT_TOKEN_ADDRESS,  // TRT token contract address
              symbol: tokenSymbol,         // TRT token symbol
              decimals: tokenDecimals,     // Number of decimals
            },
          },
        });
      } catch (error) {
        console.error("Error adding token to wallet:", error);
      }
    } else {
      alert("MetaMask is not installed!");
    }
  };

  return (

    <div className="containers">
            <AdvertisingPage/>

            <ProgressBar/>
      <h1>Listing Stage</h1>

      <div>
        <p><strong>Your Address:</strong> {userAddress}</p>
        <p><strong>Token Price:</strong> {tokenPrice} USDT</p>
        <p><strong>Available TRT:</strong> {claimableAmount} Tokens</p>
        <p><strong>Your Stake:</strong> {addressValue}</p>
      </div>
      <button onClick={claimTokens}>Claim Tokens</button>
      <button onClick={addTokenToWallet}>Add TRT Token to Wallet</button>
      

        {/* Button to import the token */}
    </div>
  );
};

export default TokenDistributor;
