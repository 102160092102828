import React from "react";
import styles from "./ContactUs.module.scss";
import { ReactComponent as MailIcon } from "../../assets/icons/landing/mail.svg";
import { ReactComponent as Telegram } from "../../assets/icons/landing/telegram.svg";
import { ReactComponent as Twitter } from "../../assets/icons/landing/twitter.svg";
import { ReactComponent as Facebook } from "../../assets/icons/landing/facebook.svg";
import { ReactComponent as Youtube } from "../../assets/icons/landing/youtube.svg";
import { ReactComponent as Tiktok } from "../../assets/icons/landing/tiktok.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/landing/linkedin.svg";
import { ReactComponent as Instagram } from "../../assets/icons/landing/Instagram.svg";
import { ReactComponent as Discord } from "../../assets/icons/landing/discord.svg";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div className={styles.contactus}>
      <div className="mx pad">
        <footer className={styles.footer}>
          <div className={styles.footer__top}>
            <div className={styles.container}>
              <div className={styles.content_left}>
                <h3>Join Our Community</h3>
                {/* <ul className={styles.lists}>
                  <li>
                    <MailIcon />
                    <span>support@yourwebsite.com</span>
                  </li>
                </ul> */}
                <ul className={styles.lists_social}>
                  <li>
                    <a
                      href="https://www.facebook.com/yourprofile"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Facebook />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/trusttradees"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Telegram />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/trustradeofficial?igsh=YzAwZjE1ZTI0Zg=="
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Instagram />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="https://www.twitter.com/yourprofile"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Twitter />
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      href="https://www.linkedin.com/yourprofile"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Linkedin />
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://www.tiktok.com/@trusttradetrt?_t=8pymjzPdgQL&_r=1"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Tiktok />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@TRTTrading-u2h"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Youtube />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="https://www.discord.com/yourprofile"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Discord />
                    </a>
                  </li> */}
                </ul>
                <ul className={styles.lists_social}>
                  <li>
                    <Link to="/terms">
                                  <button>Terms and Conditions</button>
                                </Link>
                  </li><br /><br />

                  
                  <li>
                    {/* <a
                      href="/policy"
                      target=""
                      rel=""
                    >
                      Privacy Policy
                    </a> */}
                    <br /><br />
                    <Link to="/policy">
                                  <button>Privacy Policy</button>
                                </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ContactUs;
