import React, { useEffect, useState } from 'react';
import { getUserActivity } from '../utils/getUserActivity'; // Make sure this path is correct
import styles from './TransactionHistory.module.scss'; // Make sure this path is correct
import { ethers } from 'ethers';
import FlipCard from '../utils/Depositcopy';

interface Transaction {
  name: string;
  args: any;
  blockNumber: number;
  timestamp: string;
}

const TransactionHistory: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [userAddress, setUserAddress] = useState<string | null>(null);
  const [selectedEventType, setSelectedEventType] = useState<string>('All');

  useEffect(() => {
    const connectWallet = async () => {
      try {
        const { ethereum } = window as any; // Type assertion for Ethereum provider
        if (ethereum) {
          const web3Provider = new ethers.providers.Web3Provider(ethereum as ethers.providers.ExternalProvider);
          setProvider(web3Provider);

          // Request user's account
          const accounts = await web3Provider.send("eth_requestAccounts", []);
          const address = accounts[0];
          setUserAddress(address);

          // Fetch user activity if address is available
          if (address) {
            fetchData(address, web3Provider);
          }
        } else {
          console.error('No Ethereum provider found. Install MetaMask.');
        }
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    };

    connectWallet();
  }, []);

  const fetchData = async (address: string, web3Provider: ethers.providers.Web3Provider) => {
    try {
      const userActivity = await getUserActivity(
        '0xA8e196f9Af00C9875Ad2751Be99493b074f973dF', // Replace with your contract address
        web3Provider,
        address
      );
      setTransactions(userActivity);
      setFilteredTransactions(userActivity);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const eventType = event.target.value;
    setSelectedEventType(eventType);
    if (eventType === 'All') {
      setFilteredTransactions(transactions);
    } else {
      setFilteredTransactions(transactions.filter(transaction => transaction.name === eventType));
    }
  };

  return (
    <div className={styles['transaction-history']}>
      <h2>Transaction History</h2>
      <div className={styles['filter-dropdown']}>
        <label htmlFor="eventType">Filter by Event:</label>
        <select id="eventType" value={selectedEventType} onChange={handleFilterChange}>
          <option value="All">All</option>
          <option value="Staked">Staked</option>
          <option value="Claimed">Claimed</option>
           <option value="Reward">Reward</option> 
          <option value="ReferralEarningsWithdrawn">Referral Income</option>
        </select>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : filteredTransactions.length === 0 ? (
        <p>No transactions found.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Event</th>
              <th>Details</th>
              <th>Block Number</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map((transaction, index) => (
              <tr key={index}>
                <td>{transaction.name}</td>
                <td>
                  {transaction.name === 'Staked' || transaction.name === 'Claimed' || transaction.name === 'Reward' || transaction.name === 'Withdrawn'
                    ? `Amount: ${ethers.utils.formatEther(transaction.args.amount)} USDT`
                    : transaction.name === 'ReferralEarningsWithdrawn'
                    ? `Amount: ${ethers.utils.formatEther(transaction.args.amount)} USDT`
                    : `Details: ${JSON.stringify(transaction.args)}`}
                </td>
                <td>{transaction.blockNumber}</td>
                <td>{transaction.timestamp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
    
  );
};

export default TransactionHistory;
