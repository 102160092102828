import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useSnackbar } from "notistack"; // Snackbar for notifications
import styles from "../services/Withdraw.module.scss"; // Import SCSS file

const CONTRACT_ADDRESS = "0xfAeCA75F9704Cbc551e5b7363C1236B9b8F635D9"; // Replace with actual contract address
const CONTRACT_ABI = [
  {
    constant: true,
    inputs: [{ name: "user", type: "address" }],
    name: "availableBalance",
    outputs: [{ name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "amount", type: "uint256" }],
    name: "withdrawAvailableBalance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "user", type: "address" }],
    name: "getUserInterestRate",
    outputs: [{ name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "claimAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const Withdraws: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [availableBalance, setAvailableBalance] = useState<string>("0");
  const [interestRate, setInterestRate] = useState<string>("0");
  const [loading, setLoading] = useState<boolean>(false);
  const [contract, setContract] = useState<ethers.Contract | null>(null);

  // Connect Wallet Function
  const connectWallet = async () => {
    if (!window.ethereum) {
      enqueueSnackbar("MetaMask not detected! Install MetaMask.", { variant: "error" });
      return;
    }

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      setWalletAddress(address);

      const contractInstance = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
      setContract(contractInstance);
      enqueueSnackbar("Wallet Connected!", { variant: "success" });

      // Fetch available balance after connecting
      fetchClaimableRewards(contractInstance, address);
    } catch (error) {
      console.error("Wallet connection failed:", error);
      enqueueSnackbar("Wallet connection failed!", { variant: "error" });
    }
  };

  // Fetch available balance & interest rate
  const fetchClaimableRewards = async (contractInstance?: ethers.Contract, address?: string) => {
    if (!contractInstance || !address) return;
    try {
      const balance = await contractInstance.availableBalance(address);
      setAvailableBalance(ethers.utils.formatUnits(balance, 18));

      const rate = await contractInstance.getUserInterestRate(address);
      setInterestRate(rate.toString());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Claim All Tokens
  const claimAllTokens = async () => {
    if (!contract) {
      enqueueSnackbar("Connect Wallet First!", { variant: "error" });
      return;
    }

    try {
      setLoading(true);
      const tx = await contract.claimAll();
      await tx.wait();
      enqueueSnackbar("Claim successful!", { variant: "success" });

      // Refresh data
      fetchClaimableRewards(contract, walletAddress!);
    } catch (error) {
      console.error("Claim failed:", error);
      enqueueSnackbar("Claim failed!", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when wallet is connected
  useEffect(() => {
    if (walletAddress && contract) {
      fetchClaimableRewards(contract, walletAddress);
    }
  }, [walletAddress, contract]);

  return (
    <div className={"card2"}>
                <h5>Claim TRT Tokens</h5>

      {walletAddress ? (
        <div className={styles.cardContent}>
          {/* <h5>Claim TRT Tokens</h5> */}
          <button
            className={styles.claimButton}
            onClick={claimAllTokens}
            disabled={loading}
          >
            {loading ? "Claiming..." : "Claim All"}
          </button>
        </div>
      ) : (
        <button className={styles.connectButton} onClick={connectWallet}>
          Connect Wallet
        </button>
      )}
    </div>
  );
};

export default Withdraws;
