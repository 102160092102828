import React, { useState, useEffect } from "react";
import "./LuxuryOffer.scss";

const LuxuryOffer: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [isOpen, setIsOpen] = useState(false);

  const calculateTimeLeft = () => {
    const launchDate = new Date('2025-03-27T00:00:00').getTime();
    const currentTime = new Date().getTime();
    const difference = launchDate - currentTime;

    if (difference > 0) {
      setTimeLeft({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      });
    } else {
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => calculateTimeLeft(), 1000);
    return () => clearInterval(timer);
  }, []);



  return (
    <>
      <div className="luxury-box" onClick={() => setIsOpen(true)}>
        <div className="countdown-timer">
          ⏳ Offer Ends in:<br />{timeLeft.days}d : {timeLeft.hours}h : {timeLeft.minutes}m : {timeLeft.seconds}s
        </div>
        <div className="box-content">
          <h2 className="box-title">🔒 Activate Your Account</h2>
          <p className="box-message">🎁 Click to Unlock 2% ROI!</p>
          <p className="box-subtext">🚀 Limited Time Offer for Old Members!</p>
          <button className="activate-btn">💎 Click to Activate</button>
        </div>
        
      </div>

      {isOpen && (
       <div className="popup-overlay">
      <div className="popup-box">
        <h2 className="popup-title">🔥 Special Offer for Old Members!</h2>
        <p className="popup-message">To get <strong>2% ROI</strong>, you must:</p>
        <ul className="popup-requirements">
          <li>💎 Stake the <strong>same amount</strong> as your previous investment</li>
          <li>💎 Use the <strong>same wallet</strong> as before</li>
          {/* <li>💎 Refer at least <strong>one</strong> person</li> */}
          {/* <li>💎 Complete within the next 
            <span className="timer"> <br />{timeLeft.days}d :{timeLeft.hours}h : {timeLeft.minutes}m : {timeLeft.seconds}s</span>
          </li> */}
        </ul>
        <button className="popup-btn" onClick={() => setIsOpen(false)}>Got It! 🚀</button>
        </div>
    </div>
      )}
    </>
  );
};

export default LuxuryOffer;
