import React from "react";
import "./TermsConditions.scss";

const TermsConditions: React.FC = () => {
  return (
    <div className="terms-container">
        <br /><br /><br /><br />
      <h2>Trust Trade System - Terms & Conditions</h2>

      <section>
        <h3>1. Introduction</h3>
        <p>
          Welcome to the <strong>Trust Trade</strong> staking system. By participating, you agree to follow the rules set to ensure fair play and system stability.
        </p>
      </section>

      <section>
        <h3>2. Staking & Earning Policy</h3>
        <ul>
          <li>Users can stake funds and earn passive income.</li>
          <li>Building a team is encouraged for sustainability.</li>
          <li>Accounts without direct referrals <strong>may be blocked.</strong></li>
        </ul>
      </section>

      <section>
        <h3>3. Account Blocking Policy</h3>
        <p>Your account can be blocked if:</p>
        <ul>
          <li>You are earning from staking but haven’t met the referral requirement.</li>
          <li>Any fraudulent activity is detected.</li>
        </ul>
      </section>

      <section>
        <h3>4. Unblocking Conditions</h3>
        <p>To unblock your account, fulfill the following referral requirements:</p>
        <table>
          <thead>
            <tr>
              <th>Stake Amount ($)</th>
              <th>Required Direct Referrals</th>
              <th>Referral Stake Amount ($)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1,000</td>
              <td>5</td>
              <td>100 USDT per referral</td>
            </tr>
            <tr>
              <td>5,000</td>
              <td>10</td>
              <td>100 USDT per referral</td>
            </tr>
            <tr>
              <td>10,000</td>
              <td>30</td>
              <td>100 USDT per referral</td>
            </tr>
            <tr>
              <td>20,000</td>
              <td>40</td>
              <td>100 USDT per referral</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section>
        <h3>5. Additional Rules & Guidelines</h3>
        <ul>
          <li>Your referrals must actively stake to qualify.</li>
          <li>Failure to meet conditions may keep your account restricted.</li>
          <li>The system is decentralized, and unblocking is automatic via smart contracts.</li>
          <li>No manual intervention; conditions must be met automatically.</li>
        </ul>
      </section>

      <section>
        <h3>6. Amendments & Updates</h3>
        <p>
          The system reserves the right to update these terms at any time. Users will be notified of any major changes.
        </p>
      </section>

      <footer>
        <p>
          By staking in the <strong>Trust Trade System</strong>, you agree to these terms. Non-compliance may result in permanent account restriction.
        </p>
      </footer>
    </div>
  );
};

export default TermsConditions;
